/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region Fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}
.OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}
.ArialNarrowBoldItalic {
  font-family: Arial Narrow, Arial, sans-serif;
  font-weight: 700;
  font-style: italic;
}
.MontserratRegular {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
.MontserratBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.LatoBlack {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}
html {
  font-size: 18px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  max-width: 100vw;
  overflow-x: hidden;
}
.textContent {
  font-size: 1rem;
}
.textContent h1 {
  color: #000;
  font-size: 2.4rem;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  margin-top: 0;
}
@media (max-width: 767px) {
  .textContent h1 {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .textContent h2 {
    font-size: 1.8rem;
  }
}
.lower-content h2 {
  color: #000;
  font-size: 2.4rem;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  margin-top: 0;
}
@media (max-width: 767px) {
  .lower-content h2 {
    font-size: 2rem;
  }
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}
@media (max-width: 767px) {
  .outer-wrapper {
    overflow: hidden;
  }
}
.outer-wrapper.outer-wrapper--alt .main-content__left {
  display: none;
}
.outer-wrapper.outer-wrapper--alt .main-content__main {
  width: 100%;
  padding-top: 20px;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    margin: 0 auto 0;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto 0;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
    margin: 0 auto 0;
  }
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
  padding-top: 53px;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #04033d;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  min-height: 5px;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: block !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.top-wrapper--alt .columns__left-social {
  visibility: visible !important;
}
.top-wrapper--alt .columns__container {
  margin-bottom: 0;
}
.header {
  position: relative;
  text-align: center;
  padding: 20px 0;
}
@media (max-width: 767px) {
  .header {
    padding: 40px 10px 50px;
  }
}
@media (min-width: 768px) {
  .header {
    text-align: right;
    padding: 20px 10px 10px;
  }
}
@media (min-width: 1200px) {
  .header {
    padding: 50px 0 10px;
  }
}
.header__wrapper {
  background: #000;
  position: relative;
}
.header__wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;
  padding-bottom: 1.25%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/triangle-black.svg");
  background-size: 100% 100%;
  -webkit-transform: translateY(100%) scaleY(-1);
          transform: translateY(100%) scaleY(-1);
  z-index: 1;
}
.header__container {
  padding: 0;
}
.header__logo {
  -webkit-mask-image: -webkit-gradient(linear, left top, right top, from(#000), color-stop(33.33%, #000), color-stop(66.66%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0)));
  -webkit-mask-image: linear-gradient(90deg, #000 0%, #000 33.33%, rgba(0, 0, 0, 0) 66.66%, rgba(0, 0, 0, 0) 100%);
          mask-image: -webkit-gradient(linear, left top, right top, from(#000), color-stop(33.33%, #000), color-stop(66.66%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0)));
          mask-image: linear-gradient(90deg, #000 0%, #000 33.33%, rgba(0, 0, 0, 0) 66.66%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-position: center right;
          mask-position: center right;
  -webkit-mask-size: 300% 100%;
          mask-size: 300% 100%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  z-index: 2;
}
@media (max-width: 767px) {
  .header__logo {
    display: block;
    margin: 0 auto 30px;
    max-width: 400px;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 30.8%;
    left: 0;
    top: 0;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
}
@media (min-width: 992px) {
  .header__logo {
    -webkit-transform: translateY(-27%);
            transform: translateY(-27%);
  }
}
.header__call {
  font-family: Arial Narrow, Arial, sans-serif;
  font-weight: 700;
  font-style: italic;
  color: #f4cd0d;
  font-size: 1.3rem;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .header__call {
    margin-top: 40px;
  }
}
.header__phone,
.header__phone-2 {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
}
.header__phone:hover,
.header__phone-2:hover {
  color: #fff;
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .header__social-links {
    margin: 20px auto 0;
    max-width: 250px;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    right: 285px;
    bottom: 10px;
  }
}
@media (min-width: 1200px) {
  .header__social-links {
    right: 25%;
  }
}
.header__social-link {
  width: calc(25% - 10px);
  margin: 0 5px;
  border: 2px solid #fff;
  border-radius: 50%;
  display: block;
  width: 45px;
}
@media (max-width: 767px) {
  .header__social-link {
    margin: 0 3px;
  }
}
.header__social-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.toplinks {
  position: relative;
}
.toplinks__wrapper {
  background: #08154b;
  position: relative;
}
.toplinks__wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;
  padding-bottom: 0.25%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/triangle-blue.svg");
  background-size: 100% 100%;
  -webkit-transform: translateY(100%) scaleY(-1);
          transform: translateY(100%) scaleY(-1);
}
@media (min-width: 992px) {
  .toplinks__nav {
    padding-left: 28%;
  }
}
.toplinks__container {
  padding: 0;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  text-align: center;
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li.open .dropdown-toggle:focus {
  color: #fff;
}
.toplinks__link {
  color: #f4cd0d;
  background: none;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  font-family: Arial Narrow, Arial, sans-serif;
  font-weight: 700;
  font-style: italic;
  display: block;
  padding: 35px 0;
  position: relative;
  border-bottom: 5px solid transparent;
}
@media (min-width: 768px) {
  .toplinks__link {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .toplinks__link {
    font-size: 1.2rem;
  }
}
@media (min-width: 1200px) {
  .toplinks__link {
    font-size: 1.3rem;
  }
}
.toplinks__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-top: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #fff;
}
.toplinks__link:hover:before,
.toplinks__link.active:before,
.toplinks__link:focus:before {
  opacity: 1;
}
.toplinks__dropdown {
  background: #08154b;
  text-align: center;
  padding: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.toplinks__dropdown-li {
  display: block;
  width: 100%;
  margin-left: 0;
  border-radius: 0;
}
.toplinks__dropdown-link {
  font-family: Arial Narrow, Arial, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-weight: 700 !important;
  padding: 5px 10px !important;
  color: #f4cd0d !important;
  font-size: 1.2rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background: transparent !important;
  color: #fff !important;
}
.banner {
  position: relative;
  background: #fff;
  padding: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.banner #CarouselContainer {
  padding-top: 41.75%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 8.75%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/triangle-white.svg");
  background-size: 100% 100%;
}
.banner #CarouselContainer {
  max-width: 100% !important;
  max-height: 668px !important;
}
.banner #CarouselContainer .carousel-inner {
  overflow: hidden;
}
.banner .carousel-caption {
  font-size: 45px;
  top: 25%;
  text-align: left;
  max-width: 900px;
}
@media (max-width: 767px), (min-width: 768px) and (max-width: 1329px) {
  .banner .carousel-caption {
    max-width: 100%;
    top: 5%;
    font-size: 20px;
  }
}
.testimonials {
  text-align: center;
  position: relative;
  overflow: visible;
  padding-bottom: 40px;
}
.testimonials__intro {
  color: #000;
  padding: 0 15px;
}
@media (max-width: 767px) {
  .testimonials__intro {
    margin-top: 40px;
  }
}
@media (min-width: 992px) {
  .testimonials__intro {
    text-align: right;
  }
}
.testimonials__intro h2 {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 2.4rem;
}
@media (max-width: 767px) {
  .testimonials__intro h2 {
    font-size: 1.8rem;
  }
}
.testimonials__intro h2:first-of-type {
  margin-top: 0;
}
.testimonials__quote {
  width: 62px;
}
.testimonials__quote--open {
  margin-bottom: 10px;
}
.testimonials__quote--close {
  text-align: right;
  -webkit-transform: scale(-1);
          transform: scale(-1);
  margin-left: auto;
}
.testimonials__wrapper {
  position: relative;
}
.testimonials__wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  padding-bottom: 2%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/triangle-black.svg");
  background-size: 100% 100%;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  z-index: 1;
}
.testimonials h4 {
  padding: 10px 0;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #04033d;
  font-size: 16px;
  margin-bottom: 15px 0;
}
.testimonials__snippet {
  color: #fff;
}
.testimonials__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 30px 15px 0;
  padding: 30px;
  padding-bottom: 30px;
  background: #fff;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/testimonials-bg_1x.jpg");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .testimonials__link {
    background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/testimonials-bg_2x.jpg");
  }
}
.testimonials__text {
  padding: 0 15px;
  color: #fff;
  font-size: 15px;
  line-height: 180%;
}
.testimonials__title {
  color: #fff;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}
.testimonials__author {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #fff;
  padding: 10px 0;
  background-size: 100% 100%;
  position: relative;
  text-align: center;
  font-size: 14px;
  /*align-self: flex-end;*/
}
.testimonials__all-link {
  padding: 20px 60px;
  line-height: 1;
  border-radius: 35px;
  border: 2px solid #ed1c24;
  color: #5a5959;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  display: inline-block;
}
.testimonials__all-link:hover {
  color: #5a5959;
}
/*#region Castle Panels*/
@media (min-width: 768px) {
  .main-content__wrapper {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .main-content__columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.main-content__left {
  width: 48%;
}
.main-content__main {
  width: 100%;
}
@media (min-width: 992px) {
  .main-content__main {
    width: 48%;
  }
}
.product {
  background: #fff;
  border-radius: 5px;
}
.castlePanel {
  width: 100%;
  border: none;
  padding: 12px 12px 20px;
  margin: 0 0 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #08154b;
  text-align: center;
}
@media (min-width: 768px) {
  .castlePanel {
    text-align: left;
  }
}
.castlePanel:hover {
  -webkit-box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
          box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
}
.castlePanel .castleSubtitle {
  display: none;
}
.castlePanel .SingleImagePanel {
  background: transparent;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin: 0;
  position: relative;
  height: auto;
  padding-top: 0;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
  left: 0;
}
.castlePanel .SingleImagePanel img {
  top: 0 !important;
}
@supports (mix-blend-mode:multiply) {
  .castlePanel .SingleImagePanel img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.castlePanel .castleTitlePanel {
  margin-top: 20px;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 767px) {
  .castlePanel .castleTitlePanel {
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: -webkit-box;
    display: flex;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.castlePanel .castleTitlePanel a {
  color: #fff;
  position: relative;
  font-size: 1rem;
}
@media (max-width: 767px) {
  .castlePanel .castleTitlePanel a {
    font-size: 0.8rem;
  }
}
.castlePanel .shortProductDescription {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  color: #fff;
  margin-top: 10px;
  display: none;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  color: #fff;
  position: relative;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  padding: 0 5%;
  margin-top: 10px;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  color: #fff;
}
.castlePanel .castlePriceDefault {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  display: none;
}
.castlePanel .castleCheckBook {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  background: #fff;
  border-radius: 25px;
  background: #04033d;
  height: auto;
  padding: 12px 30px;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 13px;
  position: relative;
  margin: 20px 0 0;
  letter-spacing: 0.9px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-item-align: center;
      align-self: center;
}
/*#endregion Castle Panels*/
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #04033d;
}
.DetailsTitle h1,
.DetailsTitle h2 {
  padding: 10px;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 195px;
  display: inline-block;
  margin: 20px 10px 10px;
  float: none;
}
@media (max-width: 500px) {
  .page-listings > div {
    width: 100%;
  }
}
.featured-categories {
  position: relative;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/featured-categories-bg.jpg");
  padding-bottom: 8.75%;
}
.featured-categories:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  padding-bottom: 8.75%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/triangle-white.svg");
  background-size: 100% 100%;
  z-index: 1;
}
.featured-categories__intro {
  margin-bottom: 50px;
  color: #fff;
}
.featured-categories__intro h2 {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 2.4rem;
}
@media (max-width: 767px) {
  .featured-categories__intro h2 {
    font-size: 1.8rem;
  }
}
.featured-category {
  color: #fff;
  display: block;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
}
.featured-category:hover {
  color: #fff !important;
}
@media (max-width: 420px) {
  .featured-category__col {
    width: 100% !important;
  }
}
.featured-category__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  padding: 20px 0 60px;
  position: relative;
}
@media (max-width: 767px) {
  .featured-category__title {
    font-size: 1.2rem;
    padding: 20px 0 30px;
  }
}
.newsletter {
  padding-bottom: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .newsletter {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
}
.newsletter__wrapper {
  padding: 20px 0;
}
.newsletter__left {
  color: #fff;
}
@media (min-width: 992px) {
  .newsletter__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 33%;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
}
.newsletter__main {
  margin: 0 15px;
  margin-top: 20px;
}
@media (min-width: 992px) {
  .newsletter__main {
    width: 67%;
    margin-top: 0;
  }
}
.newsletter__main > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 767px) {
  .newsletter__main > div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.newsletter__wrapper {
  position: relative;
  background: #000;
}
.newsletter__title {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 2.2rem;
  margin-bottom: 15px;
  text-align: center;
}
@media (max-width: 767px) {
  .newsletter__title {
    margin-top: 25px;
    font-size: 1.8rem;
  }
}
@media (min-width: 768px) {
  .newsletter__title {
    font-size: 2.6rem;
  }
}
@media (min-width: 992px) {
  .newsletter__title {
    text-align: left;
  }
}
.newsletter__icon {
  margin-right: 15px;
  display: none;
}
@media (min-width: 992px) {
  .newsletter__icon {
    display: block;
  }
}
.newsletter__email {
  height: 50px;
  border-radius: 35px;
  border: none;
  padding-left: 5%;
  margin: 0 2%;
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}
.newsletter__button {
  height: 50px;
  border-radius: 35px;
  background: #9e0b0f;
  border: 2px solid #ed1c24;
  color: #fff;
  text-transform: uppercase;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.newsletter__button:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .newsletter__button {
    margin-top: 15px;
    -ms-flex-item-align: center;
        align-self: center;
    padding: 0 35px;
  }
}
.contact {
  background: #08154b;
  padding: 50px 0;
  color: #fff !important;
  font-size: 1rem;
  position: relative;
}
.contact:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
  padding-bottom: 2%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/triangle-black.svg");
  background-size: 100% 100%;
  -webkit-transform: scale(-1);
          transform: scale(-1);
  z-index: 1;
}
@media (max-width: 767px) {
  .contact__logo {
    max-width: 400px;
    margin: 40px auto 0;
  }
}
.contact h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
@media (max-width: 767px) {
  .contact__content {
    text-align: center;
  }
}
.contact__content a {
  color: #fff;
}
.contact__content a:hover {
  color: #fff;
  text-decoration: underline;
}
.contact .booking-form {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  color: #fff;
  max-width: 90%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}
@media (max-width: 767px) {
  .contact .booking-form {
    max-width: 100%;
  }
}
.contact .booking-form input[type="button"] {
  background: transparent;
  padding: 15px 40px;
  border-radius: 20px;
  border: 2px solid #fff;
  float: right;
  font-size: 13px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: auto;
  width: auto;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  margin-top: 10px;
}
.contact .booking-form input[type="button"]:hover {
  color: #1869ff;
  background: #fff;
}
.contact .booking-form .form-group div:nth-child(2) {
  width: 70% !important;
}
@media (max-width: 767px) {
  .contact .booking-form .form-group div:nth-child(2) {
    width: 100% !important;
  }
}
.contact .booking-form .form-group input {
  padding: 20px;
  border-radius: 20px;
  background-color: #535c81;
  border: none;
  color: #fff;
}
.contact .booking-form .form-group input::-webkit-input-placeholder {
  color: #fff;
}
.contact .booking-form .form-group input::-moz-placeholder {
  color: #fff;
}
.contact .booking-form .form-group input::-ms-input-placeholder {
  color: #fff;
}
.contact .booking-form .form-group input::placeholder {
  color: #fff;
}
.contact .booking-form .form-group textarea {
  padding: 20px;
  border-radius: 20px;
  height: 200px;
  background-color: #535c81;
  border: none;
  color: #fff;
}
.contact .booking-form .form-group textarea::-webkit-input-placeholder {
  color: #fff;
}
.contact .booking-form .form-group textarea::-moz-placeholder {
  color: #fff;
}
.contact .booking-form .form-group textarea::-ms-input-placeholder {
  color: #fff;
}
.contact .booking-form .form-group textarea::placeholder {
  color: #fff;
}
.contact .booking-form .sectionTitle {
  display: none !important;
}
.contact .booking-form .control-label {
  text-align: left;
  width: 30%;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .contact .booking-form .control-label {
    width: 100%;
  }
}
.contact__snippet {
  line-height: 1.1;
  text-align: center;
}
.contact__snippet p {
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}
.contact__snippet p small {
  font-weight: normal;
}
@media (min-width: 992px) {
  .contact__snippet {
    text-align: left;
  }
}
.footer {
  text-align: center;
}
@media (min-width: 992px) {
  .footer {
    text-align: left;
  }
}
.footer__wrapper {
  background: #000;
  color: #fff;
  padding: 25px 10px 50px;
  width: 100%;
  z-index: 1;
  position: relative;
  margin-top: auto;
}
@media (max-width: 767px) {
  .footer__wrapper {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .footer__flex-row-md {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.footer__links {
  text-align: center;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .footer__links {
    text-align: center;
    margin-top: 15px;
  }
}
.footer__link {
  color: #fff;
  margin: 0 5px;
}
.footer__link:hover {
  color: #fff;
  text-decoration: underline;
}
.footer__logo {
  text-align: center;
}
.footer__logo img {
  height: 80px;
  margin: 0 auto;
}
.footer__copy {
  color: #fff;
}
.footer__BCN {
  display: block;
}
@media (max-width: 767px) {
  .footer__BCN {
    text-align: center;
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .footer__BCN {
    float: right;
  }
}
.footer__BCN a {
  color: #fff;
}
.footer__BCN a:hover {
  color: #fff;
  text-decoration: underline;
}
.DetailsLeft {
  background-image: none;
}
#BouncyCastleHireLink,
.bch-link {
  color: #a1a1a1;
  text-align: center;
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
@-webkit-keyframes fade {
  to {
    opacity: 0;
    -webkit-transform: rotateY(180deg) scale(1.1);
            transform: rotateY(180deg) scale(1.1);
  }
}
@keyframes fade {
  to {
    opacity: 0;
    -webkit-transform: rotateY(180deg) scale(1.1);
            transform: rotateY(180deg) scale(1.1);
  }
}
.fadeIn2 {
  -webkit-animation: fadeIn2 3.5s 0.5s 1 both;
          animation: fadeIn2 3.5s 0.5s 1 both;
}
@-webkit-keyframes fadeIn2 {
  to {
    -webkit-mask-position: center left;
            mask-position: center left;
  }
}
@keyframes fadeIn2 {
  to {
    -webkit-mask-position: center left;
            mask-position: center left;
  }
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
/*#endregion Animations*/
