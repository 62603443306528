@import "../../Autobopr/Assets/Functions.less";
@import (inline) "../../Autobopr/Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1329px)";
@desktop: ~"(min-width: 1200px)";
@mobileNavColor: #04033d;
@castleDetailsColor: #04033d;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/028/";

/*#region Fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
}

.OpenSansBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
}

.ArialNarrowBoldItalic {
	font-family: Arial Narrow,Arial,sans-serif;
	font-weight: 700;
	font-style: italic;
}

.MontserratRegular {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}

.MontserratBold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
}

.LatoBlack {
	font-family: 'Lato', sans-serif;
	font-weight: 900;
}

html {
	font-size: 18px;
}

body {
	.BodyFont;
	max-width: 100vw;
	overflow-x: hidden;
}

.textContent {
	font-size: 1rem;

	h1 {
		color: #000;
		font-size: 2.4rem;
		.LatoBlack;
		margin-top: 0;

		@media @mobile {
			font-size: 2rem;
		}
	}

	h2 {
		@media @mobile {
			font-size: 1.8rem;
		}
	}
}

.lower-content h2 {
	color: #000;
	font-size: 2.4rem;
	.LatoBlack;
	margin-top: 0;

	@media @mobile {
		font-size: 2rem;
	}
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	@media @mobile {
		overflow: hidden;
	}

	&.outer-wrapper--alt {
		.main-content__left {
			display: none;
		}

		.main-content__main {
			width: 100%;
			padding-top: 20px;
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		margin: 0 auto 0;
	}

	@media @tablet {
		width: 100%;
		padding: 0 10px;
		margin: 0 auto 0;
	}

	@media @desktop {
		width: 1170px;
		margin: 0 auto 0;
	}
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
	padding-top: 53px;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();
	min-height: 5px;

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	@media @sm {
		display: block !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
	position: relative;
	background: #fff;
	box-shadow: 0 0 10px rgba(0,0,0,.6);

	&--alt {
		.columns__left-social {
			visibility: visible !important;
		}

		.columns__container {
			margin-bottom: 0;
		}
	}
}

.header {
	position: relative;
	text-align: center;
	padding: 20px 0;

	@media @mobile {
		padding: 40px 10px 50px;
	}

	@media @tablet {
		text-align: right;
		padding: 20px 10px 10px;
	}

	@media @desktop {
		padding: 50px 0 10px;
	}

	&__wrapper {
		background: #000;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 1px;
			padding-bottom: 1.25%;
			background-image: url("@{cloudUrl}triangle-black.svg");
			background-size: 100% 100%;
			transform: translateY(100%) scaleY(-1);
			z-index: 1;
		}
	}

	&__container {
		padding: 0;
	}

	&__logo {
		mask-image: linear-gradient(90deg,#000 0%,#000 33.33%,rgba(0,0,0,0) 66.66%,rgba(0,0,0,0) 100%);
		mask-position: center right;
		mask-size: 300% 100%;
		mask-repeat: no-repeat;
		z-index: 2;

		@media @mobile {
			display: block;
			margin: 0 auto 30px;
			max-width: 400px;
		}

		@media @tablet {
			position: absolute;
			width: 30.8%;
			left: 0;
			top: 0;
			transform: translateY(-10%);
		}

		@media @md {
			transform: translateY(-27%);
		}
	}

	&__call {
		.ArialNarrowBoldItalic;
		color: #f4cd0d;
		font-size: 1.3rem;
		margin-bottom: 10px;

		@media @mobile {
			margin-top: 40px;
		}
	}

	&__phone, &__phone-2 {
		color: #fff;
		.OpenSansBold;
		font-size: 2rem;
		line-height: 1;

		@media @tablet {
		}

		&:hover {
			color: #fff;
		}
	}

	&__social-links {
		display: flex;
		justify-content: center;

		@media @mobile {
			margin: 20px auto 0;
			max-width: 250px;
		}

		@media @sm {
			position: absolute;
			right: 285px;
			bottom: 10px;
		}

		@media @md {
		}

		@media @desktop {
			right: 25%;
		}

		@media @tablet {
		}
	}

	&__social-link {
		width: ~'calc(25% - 10px)';
		margin: 0 5px;
		border: 2px solid #fff;
		border-radius: 50%;
		display: block;
		width: 45px;

		@media @mobile {
			margin: 0 3px;
		}
	}

	&__social-icon {
		.TransformMiddle;
	}
}

.toplinks {
	position: relative;

	&__wrapper {
		background: #08154b;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 1px;
			padding-bottom: 0.25%;
			background-image: url("@{cloudUrl}triangle-blue.svg");
			background-size: 100% 100%;
			transform: translateY(100%) scaleY(-1);
		}
	}

	&__nav {
		@media @md {
			padding-left: 28%;
		}
	}

	&__container {
		padding: 0;
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		text-align: center;
		list-style: none;
		position: relative;
		.Transition3s();
		flex-grow: 1;

		&.open .dropdown-toggle:focus {
			color: #fff;
		}
	}

	&__link {
		color: #f4cd0d;
		background: none;
		.transition(all 0.1s);
		.ArialNarrowBoldItalic;
		display: block;
		padding: 35px 0;
		position: relative;
		border-bottom: 5px solid transparent;

		@media @sm {
			font-size: 1.1rem;
		}

		@media @md {
			font-size: 1.2rem;
		}

		@media @lg {
			font-size: 1.3rem;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			border-top: 10px solid #fff;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			transition: all 0.3s;
			opacity: 0;
		}

		&:hover, &.active, &:focus {
			color: #fff;

			&:before {
				opacity: 1;
			}
		}
	}

	&__dropdown {
		background: #08154b;
		text-align: center;
		padding: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	&__dropdown-li {
		display: block;
		width: 100%;
		margin-left: 0;
		border-radius: 0;
	}

	&__dropdown-link {
		.ArialNarrowBoldItalic;
		font-weight: 700 !important;
		padding: 5px 10px !important;
		color: #f4cd0d !important;
		font-size: 1.2rem;
		transition: all 0.3s;

		&:hover, &:focus, &.active {
			background: transparent !important;
			color: #fff !important;
		}
	}
}

.banner {
	.BannerPanelSet(41.75%);
	position: relative;
	background: #fff;
	padding: 0;
	.border-bottom-radius(10px);
	transition: all 0.3s;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding-bottom: 8.75%;
		background-image: url("@{cloudUrl}triangle-white.svg");
		background-size: 100% 100%;
	}

	#CarouselContainer {
		max-width: 100% !important;
		max-height: 668px !important;

		.carousel-inner {
			overflow: hidden;
		}
	}

	.carousel-caption {
		font-size: 45px;
		top: 25%;
		text-align: left;
		max-width: 900px;

		@media @mobile, @tablet-only {
			max-width: 100%;
			top: 5%;
			font-size: 20px;
		}
	}
}

.testimonials {
	//heights set from theme.js
	text-align: center;
	position: relative;
	overflow: visible;
	padding-bottom: 40px;

	&__intro {
		color: #000;
		padding: 0 15px;

		@media @mobile {
			margin-top: 40px;
		}

		@media @md {
			text-align: right;
		}

		h2 {
			.LatoBlack;
			font-size: 2.4rem;

			@media @mobile {
				font-size: 1.8rem;
			}

			&:first-of-type {
				margin-top: 0;
			}
		}
	}

	&__quote {
		width: 62px;

		&--open {
			margin-bottom: 10px;
		}

		&--close {
			text-align: right;
			transform: scale(-1);
			margin-left: auto;
		}
	}

	&__wrapper {
		position: relative;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: -1px;
			padding-bottom: 2%;
			background-image: url("@{cloudUrl}triangle-black.svg");
			background-size: 100% 100%;
			transform: scaleX(-1);
			z-index: 1;
		}
	}

	h4 {
		padding: 10px 0;
		text-transform: uppercase;
		.OpenSansBold;
		color: #04033d;
		font-size: 16px;
		margin-bottom: 15px 0;
	}

	&__snippet {
		color: #fff;
	}

	&__link {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		margin: 30px 15px 0;
		padding: 30px;
		padding-bottom: 30px;
		background: #fff;
		background-image: url("@{cloudUrl}testimonials-bg_1x.jpg");

		@media @retinaMedia {
			background-image: url("@{cloudUrl}testimonials-bg_2x.jpg");
		}
	}

	&__text {
		padding: 0 15px;
		color: #fff;
		font-size: 15px;
		line-height: 180%;
	}

	&__title {
		color: #fff;
		margin-bottom: 20px;
		.OpenSansBold
	}

	&__author {
		.OpenSansBold;
		color: #fff;
		padding: 10px 0;
		background-size: 100% 100%;
		position: relative;
		text-align: center;
		font-size: 14px;
		/*align-self: flex-end;*/
	}

	&__all-link {
		padding: 20px 60px;
		line-height: 1;
		border-radius: 35px;
		border: 2px solid #ed1c24;
		color: #5a5959;
		.OpenSansBold;
		display: inline-block;

		&:hover {
			color: #5a5959;
		}
	}
}
/*#region Castle Panels*/
.main-content {
	&__wrapper {
		@media @tablet {
			padding-top: 40px;
			padding-bottom: 50px;
		}
	}

	&__columns {
		@media @md {
			display: flex;
			justify-content: space-between;
		}
	}

	&__left {
		width: 48%;
	}

	&__main {
		width: 100%;

		@media @md {
			width: 48%;
		}
	}
}

.product {
	background: #fff;
	border-radius: 5px;
}

.castlePanel {
	width: 100%;
	border: none;
	padding: 12px 12px 20px;
	margin: 0 0 30px;
	transition: all 0.3s;
	position: relative;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
	display: flex;
	flex-direction: column;
	background-color: #08154b;
	text-align: center;

	@media @tablet {
		text-align: left;
	}

	&:hover {
		box-shadow: 5px 5px 6px 0 rgba(0,0,0,.075);
	}

	.castleSubtitle {
		display: none;
	}

	.SingleImagePanel {
		background: transparent;
		order: 1;
		margin: 0;
		position: relative;
		height: auto;
		padding-top: 0;

		&:before {
			content: "";
			width: 100%;
			padding-top: 100%;
			display: block;
			left: 0;
		}

		img {
			top: 0 !important;
			@supports

			(mix-blend-mode:multiply) {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.castleTitlePanel {
		margin-top: 20px;
		padding: 0 15px;
		display: flex;
		order: 2;
		.MontserratBold;
		text-transform: uppercase;
		text-align: center;

		@media @mobile {
			.FlexCenter;
		}

		a {
			color: #fff;
			position: relative;
			font-size: 1rem;

			@media @mobile {
				font-size: 0.8rem;
			}
		}
	}

	.shortProductDescription {
		order: 3;
		color: #fff;
		margin-top: 10px;
		display: none;
	}

	.castlePriceDefault, .castlePriceDiscounted {
		color: #fff;
		position: relative;
		order: 3;
		padding: 0 5%;
		margin-top: 10px;

		span.oldprice {
			color: #fff;
		}

		span.newprice {
		}
	}

	.castlePriceDefault {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.castlePriceDefault, .castlePriceDiscounted {
		display: none;
	}

	.castleCheckBook {
		order: 4;
		background: #fff;
		border-radius: 25px;
		background: #04033d;
		height: auto;
		padding: 12px 30px;
		text-transform: uppercase;
		.OpenSansBold;
		font-size: 13px;
		position: relative;
		margin: 20px 0 0;
		letter-spacing: 0.9px;
		transition: all 0.3s;
		border: 1px solid #fff;
		display: inline-flex;
		align-self: center;
	}
}
/*#endregion Castle Panels*/
.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.DetailsTitle h1, .DetailsTitle h2 {
	padding: 10px;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 195px;
		display: inline-block;
		margin: 20px 10px 10px;
		float: none;
	}
}

.page-listings {
	& > div {
		@media (max-width:500px) {
			width: 100%;
		}
	}
}

.featured-categories {
	position: relative;
	background-image: url("@{cloudUrl}featured-categories-bg.jpg");
	padding-bottom: 8.75%;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: -1px;
		padding-bottom: 8.75%;
		background-image: url("@{cloudUrl}triangle-white.svg");
		background-size: 100% 100%;
		z-index: 1;
	}

	&__intro {
		margin-bottom: 50px;

		h2 {
			.LatoBlack;
			font-size: 2.4rem;

			@media @mobile {
				font-size: 1.8rem;
			}
		}

		color: #fff;
	}
}

.featured-category {
	color: #fff;
	display: block;
	margin-bottom: 10px;
	position: relative;
	text-align: center;

	&:hover {
		color: #fff !important;
	}

	&__image-panel {
	}

	&__image {
	}

	&__col {
		@media (max-width: 420px) {
			width: 100% !important;
		}
	}

	&__title {
		.MontserratBold;
		font-size: 1.4rem;
		padding: 20px 0 60px;
		position: relative;

		@media @mobile {
			font-size: 1.2rem;
			padding: 20px 0 30px;
		}
	}

	&__description {
	}
}

.newsletter {
	padding-bottom: 30px;
	text-align: center;

	@media @md {
		text-align: left;
		display: flex;
		align-items: flex-end;
	}

	&__wrapper {
		padding: 20px 0;
	}

	&__left {
		color: #fff;

		@media @md {
			display: flex;
			width: 33%;
			align-items: flex-end;
		}
	}

	&__main {
		margin: 0 15px;
		margin-top: 20px;

		@media @md {
			width: 67%;
			margin-top: 0;
		}

		& > div {
			display: flex;

			@media @mobile {
				flex-direction: column;
			}
		}
	}

	&__wrapper {
		position: relative;
		background: #000;
	}

	&__title {
		color: #fff;
		.MontserratRegular;
		font-size: 2.2rem;
		margin-bottom: 15px;
		text-align: center;

		@media @mobile {
			margin-top: 25px;
			font-size: 1.8rem;
		}

		@media @sm {
			font-size: 2.6rem;
		}

		@media @md {
			text-align: left;
		}
	}

	&__icon {
		margin-right: 15px;
		display: none;

		@media @md {
			display: block;
		}
	}

	&__email {
		height: 50px;
		border-radius: 35px;
		border: none;
		padding-left: 5%;
		margin: 0 2%;
		flex-grow: 2;
	}

	&__button {
		height: 50px;
		border-radius: 35px;
		background: #9e0b0f;
		border: 2px solid #ed1c24;
		color: #fff;
		text-transform: uppercase;
		.FlexCenter;
		flex-grow: 1;

		&:hover {
			color: #fff;
		}

		@media @mobile {
			margin-top: 15px;
			align-self: center;
			padding: 0 35px;
		}
	}
}

.contact {
	background: #08154b;
	padding: 50px 0;
	color: #fff !important;
	font-size: 1rem;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: -1px;
		padding-bottom: 2%;
		background-image: url("@{cloudUrl}triangle-black.svg");
		background-size: 100% 100%;
		transform: scale(-1);
		z-index: 1;
	}

	&__logo {
		@media @mobile {
			max-width: 400px;
			margin: 40px auto 0;
		}
	}

	h2 {
		.MontserratBold;
	}

	&__content {
		@media @mobile {
			text-align: center;
		}

		a {
			color: #fff;

			&:hover {
				color: #fff;
				text-decoration: underline;
			}
		}
	}

	.booking-form {
		background: transparent;
		box-shadow: none;
		border: none;
		color: #fff;
		max-width: 90%;

		@media @mobile {
			max-width: 100%;
		}

		margin: 0;
		.OpenSans;

		input[type="button"] {
			background: transparent;
			padding: 15px 40px;
			border-radius: 20px;
			border: 2px solid #fff;
			float: right;
			font-size: 13px;
			text-transform: uppercase;
			transition: all 0.3s;
			height: auto;
			width: auto;
			.OpenSansBold;
			margin-top: 10px;

			&:hover {
				color: #1869ff;
				background: #fff;
			}
		}

		.form-group {
			div:nth-child(2) {
				width: 70% !important;

				@media @mobile {
					width: 100% !important;
				}
			}

			input {
				padding: 20px;
				border-radius: 20px;
				background-color: #535c81;
				border: none;
				color: #fff;

				&::placeholder {
					color: #fff;
				}
			}

			textarea {
				padding: 20px;
				border-radius: 20px;
				height: 200px;
				background-color: #535c81;
				border: none;
				color: #fff;

				&::placeholder {
					color: #fff;
				}
			}
		}

		.sectionTitle {
			display: none !important;
		}

		.control-label {
			text-align: left;
			width: 30%;
			margin-bottom: 20px;

			@media @mobile {
				width: 100%;
			}
		}
	}

	&__snippet {
		line-height: 1.1;
		text-align: center;

		p {
			margin-bottom: 5px;
			.OpenSansBold;

			small {
				font-weight: normal;
			}
		}

		@media @md {
			text-align: left;
		}
	}
}

.footer {
	text-align: center;

	@media @md {
		text-align: left;
	}

	&__wrapper {
		background: #000;
		color: #fff;
		padding: 25px 10px 50px;
		width: 100%;
		z-index: 1;
		position: relative;
		margin-top: auto;

		@media @mobile {
			text-align: center;
		}
	}

	&__flex-row-md {
		@media @md {
			display: flex;
			align-items: center;
		}
	}

	&__links {
		text-align: center;
		margin-top: 30px;

		@media @mobile {
			text-align: center;
			margin-top: 15px;
		}
	}

	&__link {
		color: #fff;
		margin: 0 5px;

		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}

	&__logo {
		text-align: center;

		img {
			height: 80px;
			margin: 0 auto;
		}
	}

	&__copy {
		color: #fff;
	}

	&__BCN {
		display: block;

		@media @mobile {
			text-align: center;
			display: inline-block;
		}

		@media @md {
			float: right;
		}

		a {
			color: #fff;

			&:hover {
				color: #fff;
				text-decoration: underline;
			}
		}
	}
}

.DetailsLeft {
	background-image: none;
}

#BouncyCastleHireLink, .bch-link {
	color: #a1a1a1;
	text-align: center;
}
/*#region Animations*/
.wow {
	visibility: hidden;
}

@keyframes fade {
	to {
		opacity: 0;
		transform: rotateY(180deg) scale(1.1);
	}
}

.fadeIn2 {
	animation: fadeIn2 3.5s 0.5s 1 both;
}

@keyframes fadeIn2 {
	to {
		mask-position: center left;
	}
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

/*#endregion Animations*/
